<template>
    <div class="page-content w-100">
        <div class="row page-heading">
            <div class="col-lg-12 col-6">
                <p>Task Manager</p>
            </div>
            <div class="col-6">
                <div class="stylish-input-group d-lg-none d-block">
                    <input type="text" class="form-control" placeholder="Search">
                </div>
            </div>
        </div>
        <div class="accordion box-shadow mt-4" >
            <div class="d-md-none d-lg-block">
                <div class="ul-body" style="margin-top:5rem !important;">
                    <ul class="task-manager-text" id="myTab" role="tablist">
                        <li class="active-taskmanager-text" :class="selectedTask === 'opened-task' ? 'active-task': ''" role="presentation" @click="taskType('opened-task')">
                            <button class="nav-link accordion " >Open tasks</button>
                        </li>
                        <li class="active-taskmanager-text" :class="selectedTask === 'closed-task' ? 'active-task': ''" role="presentation" @click="taskType('closed-task')">
                            <button class="nav-link accordion" >Closed tasks</button>
                        </li>
                    </ul>
                        <div class="accordion" id="accordionFlushExample" v-if="selectedTask === 'opened-task'">
                            <div class="accordion-item" style="border:none"  v-for="(accordion, index) in openTaskData" :key="index" @click="taskManagerIndex(index)">
                                <div class="accordion-item" v-if="accordion?.status"  >
                                    <h2 class="accordion-header" :id="'headingTwo-' + index">
                                        <button class="accordion-button collapsed text-color border-color" style="border-radius: 5px;" type="button" data-bs-toggle="collapse"  :data-bs-target="'#flush-collapse' + index" :aria-expanded="activeAccordion === index ? 'true' : 'false'" >
                                            <strong>{{ accordion.users.firstName }} :Request for access</strong>
                                        </button>
                                    </h2>
                                    <div :id="'flush-collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'flush-heading' + index" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body p-4" v-if="index === taskIndex">
                                            <additional-practice :accordion="accordion" :key="accordion" :selectedTask="selectedTask" @triggerTaskManager="triggerTaskManager($event)" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="accordion" id="accordionFlushExample" v-if="selectedTask === 'closed-task' ">
                            <input type="text" class="form-control" placeholder="Search" v-model="search"
                                style="margin-bottom:5px;" @click="searchUser()">
                            <div class="accordion-item" style="border:none" v-for="(accordion, index) in closedTaskData" :key="index" @click="taskManagerIndex(index)">
                                <div class="accordion-item" v-if="!accordion?.status">
                                    <h2 class="accordion-header" :id="'headingTwo-' + index">
                                        <button class="accordion-button collapsed text-color border-color" style="border-radius: 5px;" type="button" data-bs-toggle="collapse"  :data-bs-target="'#flush-collapse' + index" :aria-expanded="activeAccordion === index ? 'true' : 'false'">
                                            <strong> {{ accordion.users.firstName }} :Request for access <span>
                                                    <button class="adminActionBtn">{{ accordion?.verification?.status }} </button>
                                                </span>
                                            </strong> 
                                        </button>
                                    </h2>
                                    <div :id="'flush-collapse' + index" class="accordion-collapse collapse" :aria-labelledby="'flush-heading' + index" data-bs-parent="#accordionFlushExample" >
                                        <div class="accordion-body p-4" v-if="index === taskIndex">
                                            <additional-practice  :accordion="accordion"  
                                                :selectedTask="selectedTask" :key="selectedTask + accordion"  />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdditionalPractice from './task/additionalPractice.vue'
import axios from 'axios'
export default {
    components: {
        AdditionalPractice
    },
    data() {
        return {
            userPayload: [],
            users: [],
            openTaskData: [],
            closedTaskData: [],
            taskIndex: "",
            selectedTask: 'opened-task'
        }
    },
    created: function () {
        this.getUsers();
    },
    methods: {
        triggerTaskManager(){
            this.getUsers();
        },
        taskType(task) {
            this.selectedTask  =task
            if(task==='closed-task'){
                this.getUsers();
            }
        },
        taskManagerIndex(index) {
            this.taskIndex = index;
        },
         getUsers() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/taskManagerHistory`)
                .then((response) => {
                    this.openTaskData=[];
                        this.closedTaskData=[];
                    if (response?.data) {
                        response?.data?.map(data => {
                            if (data?.status && data?.users?.status?.status != 'Approved' && data?.status && data?.users?.updated_by != 'admin') {
                                this.openTaskData.push(data)
                            } else {
                                this.closedTaskData.push(data)
                            }
                        })
                    }
                })
                .catch(function (error) {
                    console.log(error.response);
                });
        }
    }
}
</script>
<style>
li.active-task{
    color: white;
    background-color: #00979e;
    border-color: #dee2e6 #dee2e6 #fff;
    font-size: 23px;
    padding: 10px 10px;
    list-style: none;
    margin: 5px;
}
.adminActionBtn {
    background-color: #00979e;
    color: #fff;
    outline: none;
    border: none;
    width: 100px;
    height: 25px;
}

.btn.btn-blue-color.text-white.rounded-pill.p-3 {
    margin-bottom: 30px;
    width: max-content;
}

.accordion-body.p-4.text {
    margin-bottom: 18%;
}
li.active-taskmanager-text{
    list-style: none;
    padding: 10px 10px;
    margin: 5px;
}
ul.task-manager-text{
    display: flex;
    justify-content: space-around;
    font-size: 20px;
    border-bottom: 0px !important;
    color: black;
    margin-bottom: -1px;
    background: 0 0;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-size: 23px;
    
    
}



a.nav-link.active {
    width: 200px;
    height: 65px;
    font-size: large;
    text-align: center;
}

.ul-body {
    position: relative;
    bottom: 20px;
}

.tab-content.accordion>.active {
    display: inline-block;
    /* border: 1px solid black; */
    width: 100% !important;
    background-color: #fff;
}

@media screen and (max-width:1500px) {
    .tab-content.accordion>.active {
        display: inline-block;
        /* border: 1px solid black; */
        width: 88% !important;
        background-color: #fff;
    }
}
</style>
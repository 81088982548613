<template>
  <form >
    <div class="row">
      <div class="col-6">
        <label for="exampleFormControlInput1" class="form-label">Task created date</label>
        <input class="form-control cursor" type="text" aria-label="Task_date" v-model="taskDate" :disabled="true" />
      </div>
      <div class="col-6">
        <label for="exampleFormControlInput1" class="form-label">Date signed up</label>
        <input class="form-control cursor" type="text" aria-label="SignUp_date" v-model="createdAt" :disabled="true" />
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <label for="exampleFormControlInput1" class="form-label">First name</label>
        <input class="form-control " type="text" aria-label="First_Name" v-model="doctorPayload.users.firstName"
        :disabled="selectedTask === 'closed-task'" v-bind:class="[selectedTask === 'closed-task' ? 'cursor' : '']" />
      </div>
      <div class="col-6">
        <label for="exampleFormControlInput1" class="form-label">Last name</label>
        <input class="form-control" type="text" aria-label="Last_name" v-model="doctorPayload.users.lastName"
        :disabled="selectedTask === 'closed-task'"  v-bind:class="[selectedTask === 'closed-task' ? 'cursor' : '']"/>
      </div>
    </div>
    <div class="row ">
      <div class="col-6">
        <label for="exampleFormControlInput1" class="form-label">Age </label>
        <input type="number" class="form-control " aria-label="age"  v-bind:class="[selectedTask === 'closed-task' ? 'cursor' : '']" v-model="doctorPayload.users.age" :disabled="selectedTask === 'closed-task'">
      </div>
      <div class="col-6">
        <label for="exampleFormControlInput1" class="form-label">Gender</label>
        <input type="gender" class="form-control " aria-label="gender"  v-bind:class="[selectedTask === 'closed-task' ? 'cursor' : '']" v-model="doctorPayload.users.gender" :disabled="selectedTask === 'closed-task'">
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <label for="exampleFormControlInput1" class="form-label">Doctor's registration number, if any</label>
        <input class="form-control " type="text" aria-label="Doctor_registration_number"
          v-model="doctorPayload.users.registerNo" :disabled="selectedTask === 'closed-task'"  v-bind:class="[selectedTask === 'closed-task' ? 'cursor' : '']" />
      </div>
      <div class="col-6">
        <label for="exampleFormControlInput1" class="form-label">Doctor's education</label>
        <input class="form-control " type="text" aria-label="Last_name" v-model="doctorPayload.users.educational"
        :disabled="selectedTask === 'closed-task'"  v-bind:class="[selectedTask === 'closed-task' ? 'cursor' : '']" />
      </div>

      <div class="row">
        <div class="col-6">
          <label for="exampleFormControlInput1" class="form-label">Doctors's city</label>
          <input class="form-control " type="text" aria-label="Doctor's_city" v-model="Pincode"
          :disabled="selectedTask === 'closed-task'"  v-bind:class="[selectedTask === 'closed-task' ? 'cursor' : '']" />
        </div>
        <div class="col-6">
          <label for="exampleFormControlInput1" class="form-label">Doctors's communication</label>
          <input class="form-control cursor" type="text" aria-label="Doctor's_communiction" v-model="doctorPayload.users.communication.communication"
          :disabled="true" />
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12">
        <div class="form-group">
          <label class="text-color">Any other information about you that would be useful to patients </label>
          <textarea id="information" v-model="doctorPayload.users.information" name="information" class="form-control "
          :disabled="selectedTask === 'closed-task'"  v-bind:class="[selectedTask === 'closed-task' ? 'cursor' : '']"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <label for="exampleFormControlInput1" class="form-label">Pracitce areas</label>

        <input class="form-control cursor" type="text" aria-label="Practice_areas" v-model="practiceId" 
        :disabled="true" />

      </div>
    </div>


    <div class="row">
      <div class="col-6">
        <label for="exampleFormControlInput1" class="form-label">Mobile number</label>
        <input class="form-control cursor" type="text" aria-label="Mobile_Number" v-model="doctorPayload.users.mobile"
        :disabled="true" />
      </div>
      <div class="col-6">
        <label for="exampleFormControlInput1" class="form-label">Mail ID</label>
        <input class="form-control cursor" type="text" aria-label="Mail_ID" v-model="doctorPayload.users.email"
          :disabled="true" />
      </div>
    </div>


    <div class="row">
      <div class="col-lg-12">
        <label for="exampleFormControlInput1" name="message" id="message" class="form-label">Comments</label>
        <div class="col-lg-12 outline" v-for="commentText in commentData" :key="commentText">
            <span class="textstyle" > {{ commentText.comments_history }}</span>
            <p class="timestyle" v-if="commentText.comments_history">{{ commentText.createdAt }}</p>
        </div>
        <div v-if="selectedTask === 'opened-task'">
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="commentshistory"></textarea>
        </div>
      </div>
    </div>
    <div class="p-2"></div>
    <div class="col-md-8 col-8 text-center" style="margin: auto;">
      <div class="border-color p-4" style="border-radius: 20px;" v-if="selectedTask === 'opened-task'">
        <h5 class="text-color taskmanager-title">Admin actions</h5>
        <div class="status-div">
          <template v-for="value in OptCheck" :key="value">
            <div class="form-check form-check-inline" v-if="value.status !== 'UnderReview'">
              <input class="form-check-input" type="radio" name="status" :id="value.id" :value="value.id" v-model="status"
                :disabled="hideAction" :class="[selectedTask === 'closed-task' ? 'action-disabled' : '']">
              <label class="form-check-label" for="reject">{{ value.status }}</label>
            </div>
          </template>
        </div>

      </div>
    </div>
    <div class="statusStyle text-center" v-if="selectedTask === 'closed-task'">This doctor was {{ accordion?.verification?.status }}
    </div>
  </form>
  <div class="text-center mt-4" v-if="this.selectedTask === 'opened-task'">
    <button class="btn btn-blue-color text-white rounded-pill p-3" @click="selectAction()" :disabled="status !== 2 && status !==1">Close task</button>
  </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment';

export default {
  props: ['accordion', 'selectedTask'],
  data() {
    return {
      doctorPayload: [],
      Option: [],
      myResult: [],
      practiceData: [],
      Pincode: '',
      // notshow: true,
      OptCheck: [],
      createdAt: '',
      taskDate: '',
      practiceId: '',
      hideAction: false,
      commentshistory: "",
      commentData: [],
      isReject: false,
      doctorPracticeArea:[],
      status:null
      
    }
  },

  created: function () {
    if (this.accordion) {
      this.doctorPayload = this.accordion;
      this.status = this.accordion.users?.status?.id
    }
      this.getUserDetail();
    this.getStatus();
    this.getComments();
  },
  methods: {
    async getUserDetail() {
  if (this.accordion) {
    this.practiceData = [JSON.parse(this.accordion.action)];
    this.doctorPracticeArea = this.practiceData[0].practiceId;

    if (this.doctorPracticeArea) {
      this.practiceId = this.doctorPracticeArea
        .map(item => item.practiceArea)
        .join(", ");
    }
    this.Pincode = this.practiceData[0].pincode?.id;
    this.createdAt = moment(this.practiceData[0].createdAt).format(" DD MMMM YYYY ");
    this.taskDate = moment(this.accordion.task_date).format(" DD MMMM YYYY ");
  }
},
      getUsers() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/taskManagerHistory`)
                .then((response) => {
                  return response;
                    })},
      async getComments() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      await axios
        .get(`${BASE_API_URL}/commentshistory/${this.doctorPayload?.users.id}`)
        .then((response => {
          this.commentData=[];
          response.data.map((date)=>{
      date.createdAt = moment(date.createdAt).format("DD/MM/YYYY, h:mm a")})
          if (this.doctorPayload?.users?.status.status) {
            this.commentData = response.data
            // this.isReject = true;
          }
        }))
    },
    async selectAction() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      this.doctorPayload.status = this.status
      const payload = {
        users: { ...this.doctorPayload?.users, status: this.status, comments_history: this.commentshistory },
      }
      await axios
        .put(`${BASE_API_URL}/taskManagerHistory/${this.accordion.id}/update`,
          payload, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(async response => {
          this.getUserDetail();
    this.getStatus();
    this.getComments();
          this.hideAction = true
          let payloads = {
        comments_history: this.commentshistory,
        id: this.doctorPayload?.users?.id,
        doctorstatus:this.status
      }
      let reupdatePayload = {
                firstName: this.doctorPayload.users.firstName,
                lastName: this.doctorPayload.users.lastName,
                mobile: this.doctorPayload.users.mobile,
                email: this.doctorPayload.users.email,
                pincode: this.doctorPayload.users.pincode,
                registerNo: this.doctorPayload.users.registerNo,
                educational: this.doctorPayload.users.educational,
                information: this.doctorPayload.users.information,
                gender:this.doctorPayload.users.gender,
                updated_by:'admin'
            };
      await axios 
      .put(`${BASE_API_URL}/hcps/${this.doctorPayload.users.id}/update`, reupdatePayload)
      .then(async (response)=>{
        this.getUserDetail();
    this.getStatus();
    this.getComments();
        if(response.data){
          await axios
        .post(`${BASE_API_URL}/commentshistory`, payloads, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(response => {
          this.getUserDetail();
    this.getStatus();
    this.getComments();
          this.commentshistory = "";
          this.$emit("triggerTaskManager", true);
          return response
        })
        }
      })
          return response
        })
        .catch((err) => {
          return err
                })
    },
    async getStatus() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      await axios
        .get(`${BASE_API_URL}/status`)
        .then((response) => {
          if (response?.data) {
            this.OptCheck = response.data;
          }
        })
        .catch(function (error) {
          return error
                });
    },
  }
}
</script>
<style>
.statusStyle {
  font-size: 25px;
  font-weight: bold;
  color: #d33a1d;
}

span.textstyle {
  font-size: 20px;
  font-weight: 700px;
}

p.timestyle {
  font-size: 12px;
  color: #545759;
}

.col-lg-12 .outline {
  border: 2px solid #e9ecef !important;
  padding: 0.375rem 0.75rem;
  margin: 5px 0px;
}

.form-control.cursor {
  cursor: not-allowed;
}

.action-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.status-div {
  display: flex;
  justify-content: space-evenly;
}
</style>